export const calculateBatchSize = (rowCount) => {
    if (rowCount <= 100) return 100;
    if (rowCount <= 1000) return 100;
    if (rowCount <= 5000) return 200;
    if (rowCount <= 10000) return 500;
    if (rowCount <= 50000) return 1000;
    if (rowCount <= 100000) return 2000;
    if (rowCount <= 500000) return 3000;
    if (rowCount <= 1000000) return 5000;
    return 7500; // Default for rows > 1,000,000
};