import { useEffect, useState } from 'react';
import styles from '../../../styles/component-styles/admin/upload.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { selectUploadSongsStats, selectUploadingSongs, selectLastUpdated, selectStartingId, cancelUploadSongAsync, selectLastId, selectProgress } from '../../../redux/slice/song/song-slice';
export function UploadProgress({ onDelete = () => { } }) {
    const isUploading = useSelector(selectUploadingSongs)
    const progress = useSelector(selectProgress)
    const lastDate = new Date(useSelector(selectLastUpdated))
    const uploadSongsStats = useSelector(selectUploadSongsStats)
    const handleDelete = () => {
        onDelete()
    }
    return (
        <div className={styles.uploadInfoModal}>
            <div className={styles.content}>

                {isUploading ? <div className={styles.progress}>
                    <p className={styles.uploading}>{Number(progress).toFixed(2)}% Uploading...</p>
                </div> : uploadSongsStats?.totalSongs > 0 && <>
                    <div className={styles.action}>
                        <p className={styles.lastDate}>Last Updated: {lastDate.toLocaleString()}</p>
                        {/* <p className={styles.totalSongs}>Total Songs: {uploadSongsStats}</p> */}
                        <button className={styles.delete} onClick={handleDelete}>Delete</button>
                    </div>
                    <div className={styles.action} style={{ padding: "20px", color: "#fff", background: "#333", borderRadius: "8px" }}>
                        <div className={styles.action} >

                            <div className={styles.songsState}><strong>Total Songs:</strong> {uploadSongsStats.totalSongs}</div>
                            <div className={styles.songsState}><strong>Uploaded Songs:</strong> {uploadSongsStats.totalUpload}</div>
                        </div>
                        <div className={styles.action} >
                            <div className={styles.songsState}><strong>Skipped Songs:</strong> {uploadSongsStats.totalSkiped}</div>
                            <div className={styles.songsState}><strong>Already Found:</strong> {uploadSongsStats.totalAlreadyFound}</div>
                        </div>

                    </div>
                    <p className={styles.note}>
                        <i>
                            <b>*Note:</b>
                            <ul>
                                <li>Skipped songs are songs where the artist, title, or genre is empty.</li>
                                <li>Already found songs are songs that already exist in the system with the same artist, title, and genre.</li>
                            </ul>
                        </i>
                    </p>

                </>
                }
            </div>
        </div >
    )
}